import debounce from 'viewmodels/debounce';
import cr from '@cheqroom/core';
import pt from 'viewmodels/pagetypes.js';
import central from 'viewmodels/central.js';
import msgHandler from 'viewmodels/message.js';
import errHandler from 'viewmodels/error.js';
import keyboardShortcuts from 'viewmodels/keyboard-shortcuts.js';
import moment from 'moment';
import TimeZoneModal from 'viewmodels/timezone-modal.js';
import { mount as mountReact } from '@cheqroom/web';
import featureFlags from '@cheqroom/web/src/services/feature-flags';
import authentication from '@cheqroom/web/src/services/authentication';
import UpgradeModal from '../viewmodels/upgrade-modal';
import SubscriptionLimitModal from '../viewmodels/subscription-limit-modal';
import { DateTime } from '@cheqroom/date-time';

import deletedUserIcon from '../../../public/icons/deleted-user.svg';

var that = {
	authenticate: function () {
		return authentication.authenticate().then(
			([accessToken, sessionToken]) => {
				// Based on the given accessToken (from either login or from refresh), we save the parsed token to localStorage
				const decodedJwt = authentication.decode(accessToken);

				this.authUser.userId = decodedJwt.sub;
				this.authUser.tokenType = 'jwt';
				this.authUser.userToken = sessionToken;
				this.authUser.email = decodedJwt.email;
				this.authUser.impersonated = decodedJwt.impersonating;
				this.authUser.toStorage();

				// TODO this should be removed but some iCal notification still contain routes with hashes
				// window.location.pathname returns '/' if you are clicking on a hash-route
				// thus it will always redirect you to / instead of /#reservations/...
				// So we need to check first if you are clicking on a hash-route
				// And if so, redirect you to the hash-route instead of the pathname
				const currentPath = window.location.hash
					? `/${window.location.hash.replaceAll('#', '')}`
					: window.location.pathname;
				const workspace = decodedJwt.workspace;

				if (!currentPath.includes(workspace)) {
					let url = `/${workspace}${currentPath}`;

					if (window.location.search) {
						url = `${url}${window.location.search}`;
					}

					history.replaceState({}, undefined, url);
				}

				// create cookie to indicate on website if user has account or not (launch vs signup)
				const date = new Date();
				date.setTime(date.getTime() + 365 * 10 * 24 * 60 * 60 * 1000);
				document.cookie = `cheqroom-userid=${decodedJwt.sub.toString()}; expires=${date.toUTCString()}; path=/; domain=.cheqroom.com`;

				return that.load(decodedJwt.sub, decodedJwt.impersonating);
			},
			() => {
				authentication.redirectToLogin();

				// BUGFIX: make sure to reject
				// so durandal app start doesn't continue with loading app shell
				return Promise.reject();
			}
		);
	},
	/**
	 * global.init
	 * called in `main.js` after `app.start()`
	 */
	init: function (app) {
		// Expose global on window
		window.global = this;

		// Stuff for api access
		this.api = {};
		this.ajax = new cr.api.ApiAjax({
			timeOut: 30000,
			customHeaders: () => {
				return authentication
					.getAccessToken()
					.then((jwt) => {
						return {
							Authorization: `Bearer ${jwt}`,
						};
					})
					.catch((e) => {
						authentication.redirectToLogin();

						// rethrow the exception so that the caller of this function will
						// not continue.
						throw e;
					});
			},
		});

		// Create user object
		// Initialize user from storage, so user doesn't have to login every time
		this.authUser = new cr.api.ApiUser({
			tokenType: 'jwt',
		});
		this.authUser.fromStorage();

		this.app = app;
		this.central = central; // module with all central data; User, Group, Stats, Locations, Location, ...
		this.permissionHandler = null;
		this.storageHandler = null;
		this.common = cr.common;

		errHandler.attachToWindow();

		// Subscribe to app-wide events
		this.app.on('notification').then(msgHandler.showNotification);
		this.app.on('error').then(msgHandler.showError);
		this.app.on('busy').then(msgHandler.showBusy);
		this.app.on('sessionExpired').then(
			debounce(function () {
				// BUGFIX only call doLogout if global is initialized
				if (typeof global != 'undefined') {
					authentication.redirectToLogin();
				}
			}, 150)
		);
		this.app.on('intercom:event').then(function (meta) {
			// Intercom loaded?
			if (window.Intercom != null) {
				try {
					var eventName = meta.name;
					delete meta['name'];
					window.Intercom('trackEvent', eventName, meta);
				} catch (ex) {
					//Ignore intercom error
				}
			}
		});
		this.app.on('upgrade:event').then(function (featureName) {
			app.trigger('intercom:event', { name: 'web-clicked-upgrade', feature: featureName });
		});

		window.onoffline = this.central.onOffline;
		window.ononline = this.central.onOnline;
	},
	isLoggingOut: function () {
		return that._loggingOut;
	},
	doLogout: function (addTrigger) {
		that._loggingOut = true;

		// Make sure SSO is also logged out
		// BUGFIX: can't use .call() because if we use JWT it will fail,
		// so force it to use refresh token to make this api call
		if (!that.authUser.impersonated && that.authUser.userToken && that.authUser.userToken !== 'null') {
			$.get(`${that.getDataSource('users').getBaseUrl(true)}${that.authUser.userId}/call/signout`);
		}

		// Clear authUser
		if (that.authUser) {
			that.authUser.clearToken();
		}

		authentication.logout();

		if (addTrigger || addTrigger === undefined) {
			// Trigger logout event
			that.app.trigger('intercom:event', { name: 'web-logout' });
		}

		if (window.Intercom !== null) {
			try {
				window.Intercom('shutdown');
			} catch (ex) {
				//ignore intercom shutdown error
			}
		}

		setTimeout(function () {
			const loginUrl = new URL(`${process.env.LOGIN_URL}/logout`);
			window.location.replace(loginUrl);
		}, 200);
	},
	/**
	 * Loads the global object for a certain userId
	 * It's called after `authenticate` succeeds
	 * @param userId
	 * @returns {promise}
	 */
	load: function (userId, impersonating) {
		// First make a storageHandler based on the userId
		// As long as there's not userId,
		// calls to getStorageHandler will return null
		this.storageHandler = pt.storageHandler({ prefix: userId });

		// Before we proceed to load central,
		// we need to check that any location saved in storageHandler
		// still exists, if not, just clear it
		// TODO

		var that = this;

		// Initialize central
		return this.central.init({ global: this, app: this.app, userId: userId }).then(function (user) {
			mountReact();

			var isFirstLogin = user.created && moment().diff(user.created, 'minutes') < 1;

			// Init keyboard shortcuts
			keyboardShortcuts.init({ global: that, app: app });

			that.helper = new cr.Helper({
				qrCodeUtilsApi: process.env.QR_CODES_API_URL,
				barcodeUtilsApi: process.env.BAR_CODES_API_URL,
			});

			// Create a date helper object based on group profile settings
			that.dateHelper = new cr.DateHelper({
				roundMinutes: user.group.profile.roundMinutes,
				roundType: user.group.profile.roundType,
				timeFormat24: user.group.profile.timeFormat24,
				businessHours: user.group.businessHours,
				weekStart: user.group.profile.weekStart,
			});

			// Load the templates we can generate for checkouts, reservations, customers
			// but don't block for the response
			if (that.permissionHandler.hasPermission('read', 'templates')) {
				that.central.loadTemplates();
			}

			// Check timezone
			// (wait until browser deprecated check has been done and modal is hidden before
			// showing timezone modal)
			if (!impersonating) {
				const computerTimezone = DateTime.getTimeZone();
				const profileTimezone = user.timezone;

				// https://stackoverflow.com/a/29268535
				function getTimeZoneOffset(date, timeZone) {
					// Abuse the Intl API to get a local ISO 8601 string for a given time zone.
					let iso = date.toLocaleString('en-CA', { timeZone, hour12: false }).replace(', ', 'T');

					// Include the milliseconds from the original timestamp
					iso += '.' + date.getMilliseconds().toString().padStart(3, '0');

					// Lie to the Date object constructor that it's a UTC time.
					const lie = new Date(iso + 'Z');

					// Return the difference in timestamps, as minutes
					// Positive values are West of GMT, opposite of ISO 8601
					// this matches the output of `Date.getTimeZoneOffset`
					return -(lie - date) / 60 / 1000;
				}

				if (computerTimezone && profileTimezone !== computerTimezone) {
					new Promise((resolve) => {
						//Check if timezone offset is different,
						//if not silently update timezone
						const now = new Date();
						if (getTimeZoneOffset(now, computerTimezone) !== getTimeZoneOffset(now, profileTimezone)) {
							TimeZoneModal.show({
								computerTimezone: computerTimezone,
								profileTimezone: profileTimezone,
							}).then(function () {
								resolve();
							});
						} else {
							resolve();
						}
					}).then(function () {
						that.central.userUpdate({
							timezone: computerTimezone,
						});
					});
				}
			}

			// Initialize Intercom for this session
			var shutdownIntercom = function () {
				// Clear old user data
				window.Intercom('shutdown');
				that.intercomLoaded = false;
			};

			var bootIntercom = function () {
				// http://docs.intercom.io/installing-Intercom/integrating-intercom-in-one-page-app
				window.Intercom('boot', {
					app_id: process.env.INTERCOM_ID,
					email: user.email,
					user_id: user._id,
					company: { id: user.group._id, name: user.group.name }, // Needed to count the company sessions as well
					created_at: user.group.activated.unix(), // This is the account activation date, we don't have user.created field yet
					widget: { activator: '#IntercomDefaultWidget' },
					is_in_beta_program: that.central._group().isInBetaProgram,
				});

				that.intercomLoaded = false;
				Intercom('onUnreadCountChange', function (unreadCount) {
					that.intercomLoaded = true;
				});
			};

			app.on('intercom:shutdown', function () {
				shutdownIntercom();
			});
			app.on('intercom:boot', function () {
				shutdownIntercom();
				bootIntercom();
			});

			if (
				window.Intercom != null &&
				that.permissionHandler.hasContactReadOtherPermission() &&
				user.profile.useIntercom &&
				!impersonating
			) {
				bootIntercom();
			}

			// Identify user in hubspot
			if (process.env.HUBSPOT_ID && !impersonating) {
				import('hubspot').then(function (_hsq) {
					//Ignore selfservice role
					if (that.permissionHandler.hasContactReadOtherPermission()) {
						_hsq.default.push([
							'identify',
							{
								email: user.email,
								id: user._id,
							},
						]);
						_hsq.default.push(['trackPageView']);
					}
				});
			}

			// refresh navigation whenever the featureFlags change
			const refreshNavigation = () => {
				that.central._group.notifySubscribers();
				app.trigger('refresh.navigation');
			};
			featureFlags.onFileReload(refreshNavigation);
			featureFlags.onReady().then(refreshNavigation);

			return that.central.reload().then(function () {
				window.dispatchEvent(new Event('app:loaded'));
			});
		});
	},
	getAnonymousApi: function () {
		return this.getDataSource('anon');
	},
	getDataSource: function (coll) {
		if (coll == 'contacts') {
			coll = 'customers';
		}
		var ds = this.api[coll];
		if (ds == null) {
			switch (coll) {
				// Special
				case 'home':
				case 'availabilities':
				// Core
				case 'items':
				case 'kits':
				case 'reservations':
				case 'orders':
				case 'customers':
				// Core (admin)
				case 'locations':
				case 'categories':
				case 'codes':
				case 'webhooks':
				case 'templates':
				case 'syncs':
				case 'reporting':
				case 'invites':
				case 'notifications':
				case 'integrations':
				case 'spotchecks':
				case 'attachments':
				case 'catalog':
				// Global
				case 'groups':
				case 'users':
					ds = new cr.api.ApiDataSource({
						collection: coll,
						urlApi: process.env.API_URL,
						ajax: this.ajax,
						user: this.authUser,
						version: '2.6.4',
					});
					break;
				default:
					ds = new cr.api.ApiAnonymous({
						urlApi: process.env.API_URL,
						// anonymous api datasource can't use jwt headers
						ajax: new cr.api.ApiAjax({
							timeout: 30000,
						}),
						version: '2.6.4',
					});
					break;
			}
			this.api[coll] = ds;
		}

		return ds;
	},
	getStorageHandler: function () {
		return this.storageHandler;
	},
	getPermissionHandler: function () {
		return this.permissionHandler;
	},
	getErrorHandler: function () {
		return errHandler;
	},
	getMessageHandler: function () {
		return msgHandler;
	},

	/**
	 * Helpers
	 */
	getItemImageUrl: function (item, size) {
		return item && item.cover_url ? item.cover_url[size] : '';
	},
	getKitImageUrl: function (kit, size) {
		return kit && kit.cover_url ? kit.cover_url[size] : '';
	},
	getContactImageUrl: function (contact, size) {
		if (contact?.status === 'deleted') {
			return deletedUserIcon;
		}

		if (contact && contact.name && contact.name.length === 0 && !contact.cover) {
			if (contact.kind == 'maintenance') {
				return "data:image/svg+xml,%3Csvg viewBox='0 0 150 150' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Ccircle id='Oval' fill='%23999999' cx='75' cy='75' r='75'%3E%3C/circle%3E%3Cpath d='M57.4726319,62.3385013 L92.6984594,62.3385013 C93.2706563,62.3385013 93.7345133,61.8838973 93.7345133,61.3231149 L93.7345133,57.2615692 C93.7345133,56.7007867 93.2706563,56.2461828 92.6984594,56.2461828 L91.6624056,56.2461828 C91.6642219,50.5319895 88.1747203,45.3721515 82.8041462,43.1476979 L79.2297608,50.1538642 L79.2297608,42.0307729 C79.2297608,40.909208 78.3020466,40 77.1576532,40 L73.0134382,40 C71.8690448,40 70.9413307,40.909208 70.9413307,42.0307729 L70.9413307,50.1538642 L67.3669453,43.1476979 C61.9963711,45.3721515 58.5068695,50.5319895 58.5086857,56.2461828 L57.4726319,56.2461828 C56.9004352,56.2461828 56.4365782,56.7007867 56.4365782,57.2615692 L56.4365782,61.3231149 C56.4365782,61.8838973 56.9004352,62.3385013 57.4726319,62.3385013 L57.4726319,62.3385013 Z M86.6,84.6770026 L84.4379464,84.6770026 C78.4490539,87.3866554 71.5509461,87.3866554 65.5620536,84.6770026 L63.4,84.6770026 C53.7902453,84.6770026 46,92.3200922 46,101.74832 C46,103.544173 47.4838563,105 49.3142857,105 L100.685714,105 C102.516144,105 104,103.544173 104,101.74832 C104,92.3200922 96.2097548,84.6770026 86.6,84.6770026 Z M75,80.6459948 C83.4314011,80.6459948 90.311486,74.4076804 91.339233,66.369509 L58.660767,66.369509 C59.6885136,74.4076804 66.5685989,80.6459948 75,80.6459948 Z' id='Shape' fill='%23FFFFFF'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";
			} else if (contact.kind == 'flag') {
				return "data:image/svg+xml,%3Csvg viewBox='0 0 150 150' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Ccircle id='Oval' fill='%23999999' cx='75' cy='75' r='75'%3E%3C/circle%3E%3Cpath d='M57.4726319,62.3385013 L92.6984594,62.3385013 C93.2706563,62.3385013 93.7345133,61.8838973 93.7345133,61.3231149 L93.7345133,57.2615692 C93.7345133,56.7007867 93.2706563,56.2461828 92.6984594,56.2461828 L91.6624056,56.2461828 C91.6642219,50.5319895 88.1747203,45.3721515 82.8041462,43.1476979 L79.2297608,50.1538642 L79.2297608,42.0307729 C79.2297608,40.909208 78.3020466,40 77.1576532,40 L73.0134382,40 C71.8690448,40 70.9413307,40.909208 70.9413307,42.0307729 L70.9413307,50.1538642 L67.3669453,43.1476979 C61.9963711,45.3721515 58.5068695,50.5319895 58.5086857,56.2461828 L57.4726319,56.2461828 C56.9004352,56.2461828 56.4365782,56.7007867 56.4365782,57.2615692 L56.4365782,61.3231149 C56.4365782,61.8838973 56.9004352,62.3385013 57.4726319,62.3385013 L57.4726319,62.3385013 Z M86.6,84.6770026 L84.4379464,84.6770026 C78.4490539,87.3866554 71.5509461,87.3866554 65.5620536,84.6770026 L63.4,84.6770026 C53.7902453,84.6770026 46,92.3200922 46,101.74832 C46,103.544173 47.4838563,105 49.3142857,105 L100.685714,105 C102.516144,105 104,103.544173 104,101.74832 C104,92.3200922 96.2097548,84.6770026 86.6,84.6770026 Z M75,80.6459948 C83.4314011,80.6459948 90.311486,74.4076804 91.339233,66.369509 L58.660767,66.369509 C59.6885136,74.4076804 66.5685989,80.6459948 75,80.6459948 Z' id='Shape' fill='%23FFFFFF'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";
			} else {
				return cr.common.getIconAvatar(size, 'f007', '#fff', '#9ba9bf');
			}
		}
		if (contact && contact.cover && contact.cover.indexOf('data:') != -1) {
			return contact.cover;
		}

		if (contact) {
			if (contact.cover_url && cr.common.isImage(contact.cover)) {
				return contact.cover_url[size];
			} else if (contact.user && contact.user.picture_url) {
				return contact.user.picture_url[size];
			}
		}
		return '';
	},
	getUserImageUrl: function (user, size) {
		if (user?.status === 'deleted') {
			return deletedUserIcon;
		}

		if (user.kind == 'sms') {
			return cr.common.getIconAvatar(size, 'f27b', '#fff', '#9ba9bf');
		}
		if (user.kind == 'push') {
			return cr.common.getIconAvatar(size, 'f10b', '#fff', '#9ba9bf');
		}
		if (user.kind == 'email') {
			return cr.common.getIconAvatar(size, 'f0e0', '#fff', '#9ba9bf');
		}
		if (user.kind == 'flag') {
			return `data:image/svg+xml,%3Csvg viewBox='0 0 150 150' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%0A%3E%3Cg%3E%3Ccircle id='Oval' fill='${user.color}' cx='75' cy='75' r='75'%3E%3C/circle%3E%3Cpath d='M41.976,52.362c-0,-2.501 2.027,-4.528 4.527,-4.528l56.985,0c4.033,0 6.053,4.877 3.201,7.729l-16.235,16.235c-1.768,1.769 -1.768,4.635 -0,6.404l16.235,16.235c2.852,2.852 0.832,7.729 -3.201,7.729l-56.985,-0c-2.5,-0 -4.527,-2.027 -4.527,-4.528l-0,-45.276Z' id='Shape' fill='%23FFFFFF' /%3E%3C/g%3E%3C/svg%3E`;
		}
		return user && user.picture_url ? user.picture_url[size] : '';
	},
	getAttachmentImageUrl: function (attachment, size) {
		// is url
		if (typeof attachment === 'string' && attachment.indexOf('http') == 0) return attachment;

		var url = attachment ? attachment.value_url || attachment.attachmentId_url || attachment._id_url : null;

		return url ? url[size] : '';
	},

	/**
	 * Global error handler
	 */
	onError: function (err, unrecoverable) {
		// Skip if err is undefined
		if (!err) return;

		errHandler.onError(err, unrecoverable);

		// BUGFIX Return err object, otherwise durandal error
		// in some occasions (Cannot read property 'message' of undefined)
		return err;
	},
	toggleReactifiedView: function () {
		document.querySelector('body').classList.toggle('reactified-view');
	},
	UpgradeModal,
	SubscriptionLimitModal,
};

export default that;
