import { ElementType, FC, ReactNode } from 'react';

import { StyledSkeleton } from './Skeleton.styles';

export interface Props {
	height?: string;
	width?: string;
	children?: ReactNode;
	as?: ElementType;
}

export const Skeleton: FC<Props> = ({ children, height, width, as, ...rest }) => (
	<StyledSkeleton as={as} height={height} width={width} {...rest}>
		{children}
	</StyledSkeleton>
);
