import { keyframes } from 'styled-components';
import tw, { css, styled } from 'twin.macro';

import { Props } from './Skeleton';

const shimmerAnimation = keyframes`
	0% {
		background-position: 200% 0;
	}
	100% {
		background-position: -200% 0;
	}
`;

export const shimmer = css`
	${tw`bg-gradient-to-r from-gray-300 via-gray-100 to-gray-300 animate-pulse`}

	background-size: 400% 100%;
	animation: ${shimmerAnimation} 8s ease-in-out infinite;
`;

type StyledProps = Pick<Props, 'height' | 'width'>;

export const StyledSkeleton = styled.span<StyledProps>`
	${tw`rounded block`}

	height: ${({ height }) => height};
	width: ${({ width }) => width};

	${shimmer}
`;
